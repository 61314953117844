import React, { useState, useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

export default function StudentHome(props) {
  const navigate = useNavigate();
  const [assignments, setAssignments] = useState([]);
  const { setActiveAssignment, authorizedFetch } = useOutletContext();

  useEffect(() => {
    async function fetchAssignments() {
      const data = await authorizedFetch(
        `${process.env.REACT_APP_BACKEND_URL}/student/assignments`
      );
      if (data?.assignments) {
        setAssignments(data.assignments);
      }
    }
    fetchAssignments();
  }, [authorizedFetch]);

  // Filter and sort assignments by soonest due date
  const inProgress = assignments
    .filter((e) => e.due_date && e.questions_done < e.num_questions)
    .sort((a, b) => new Date(a.due_date) - new Date(b.due_date))
    .slice(0, 20);

  const classesWithAssignments = [...new Set(assignments.map((e) => e.class_name))];

  function doAssignment(task) {
    setActiveAssignment(task);
    navigate("/student/chat");
  }

  return (
    <div className="studentHomeContainer">
      <div className="welcomeBox">
        <h3>Welcome, {props.auth?.name}</h3>
        <p><b>Your Classes</b></p>
        <hr />
        <div className="classList">
          {classesWithAssignments.map((className, i) => (
            <div className="classButtonWrapper" key={i}>
              <button
                className="classButton"
                onClick={() => navigate(`/student/class/${className}`)}
              >
                <span className="starSymbol">★</span>
                <div className="classTextContainer">
                  <span className="className">{className}</span>
                  <span className="assignmentCount">
                    {assignments.filter(e => e.class_name === className && e.questions_done < e.num_questions).length} incomplete assignment(s)
                  </span>
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>

      <div className="assignmentsSection">
        <p className="upcoming-assignments"><b>Upcoming Assignments</b></p>

        {inProgress.length > 0 ? (
          inProgress.map((task, i) => (
            <div key={i}>
              {/* Due Date above the assignment box */}
              <p className="due-date-text">
                Due: {new Date(task.due_date).toLocaleDateString()}
              </p>
              
              <div className="assignment-container">
                {/* Class Name */}
                <div className="class-name">{task.class_name}</div>
                
                {/* Assignment Info */}
                <div className="assignment-info">
                  <h3>{task.name}</h3>
                  <p>{task.questions_done}/{task.num_questions} Questions Completed</p>
                </div>

                {/* Start Button */}
                <button
                  className="start-button"
                  onClick={() => doAssignment(task)}
                >
                  Start!
                </button>
              </div>

              {/* Divider Line */}
              {i < inProgress.length - 1 && <hr className="assignment-divider" />}
            </div>
          ))
        ) : (
          <p>No assignments with upcoming due dates.</p>
        )}
      </div>
    </div>
  );
}
