import 'mathlive';

import React, {useState, useEffect} from "react";
import styles from '../styles/AnswerBox.module.css';

export default function AnswerBox(props) {
  var {type, sendMessage} = props
  var tableSize = 3;

  //this stuff isn't tested
  if (type && type.startsWith('Table-')) {
    tableSize = parseInt(type.split('Table-')[1])
    type = 'Table'
  } else if (type && type.startsWith('UnorderedList-')) {
    tableSize = parseInt(type.split('UnorderedList-')[1])
    type = 'UnorderedList'
  }

  const isExpression = type === 'Expression'
  const [newAnswer, setNewAnswer] = useState("")
  const [mathSetInput, setMathSetInput] = useState([])
  const [tableInput, setTableInput] = useState([[], []])
  const [twoWayTableInput, setTwoWayTableInput] = useState([[], [], []])

  function modifyTableInput(row, col, val) {
    if (row === 0) {
      setTableInput(prev => [
        [...prev[0].slice(0, col), val, ...prev[0].slice(col + 1)],
        prev[1]
      ])
    } else if (row === 1) {
      setTableInput(prev => [
        prev[0],
        [...prev[1].slice(0, col), val, ...prev[1].slice(col + 1)],
      ])
    }
  }

  function modifyTwoWayTableInput(row, col, val) {
    console.log(row, col, val)
    if (row === 0) {
      setTwoWayTableInput(prev => [
        [...prev[0].slice(0, col), val, ...prev[0].slice(col + 1)],
        prev[1],
        prev[2]
      ])
    } else if (row === 1) {
      setTwoWayTableInput(prev => [
        prev[0],
        [...prev[1].slice(0, col), val, ...prev[1].slice(col + 1)],
        prev[2]
      ])
    } else if (row === 2) {
      setTwoWayTableInput(prev => [
        prev[0],
        prev[1],
        [...prev[2].slice(0, col), val, ...prev[2].slice(col + 1)],
      ])
    }
  }

  function modifyMathSetInput(value, index) {
    if (index === 0) {
      setMathSetInput(prev => [
        value, prev[1]
      ])
    } else if (index === 1) {
      setMathSetInput(prev => [
        prev[0], value
      ])
    }
  }

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === '\\') {
        e.preventDefault(); // Prevent LaTeX mode from being triggered
        // Insert the backslash as regular text if needed
        document.getElementById('formula-input').mathVirtualKeyboardPolicy = 'manual';
      } else if (e.key === 'Escape') {
        e.preventDefault(); // Prevent Escape from triggering LaTeX mode
      }
    };

    if (document.getElementById('formula-input') != null) {
      document.getElementById('formula-input').mathVirtualKeyboardPolicy = 'manual';
    }

    // Adding the event listener to capture the key events
    document.addEventListener('keydown', handleKeyDown, { capture: true });

    // Cleanup the event listener
    return () => {
      document.removeEventListener('keydown', handleKeyDown, { capture: true });
    };
  }, [type]);

  useEffect(() => {
    if (tableSize) {
      setTableInput([
        [...Array(tableSize).keys()].map(e => ""),
        [...Array(tableSize).keys()].map(e => "")
      ]);
      setTwoWayTableInput([
        [...Array(tableSize).keys()].map(e => ""),
        [...Array(tableSize).keys()].map(e => ""),
        [...Array(tableSize).keys()].map(e => "")
      ]);
    }
  }, [tableSize])

  function answerOnKeyDown(e) {
    if (e.key.toLowerCase() === "enter") {
      sendMessage(e.target.value, isExpression);
      setNewAnswer("");
    }
  }

  function submitAnswer() {
    if (type === 'Table') {
      const xs = tableInput[0].map(e => parseFloat(e))
      const ys = tableInput[1].map(e => parseFloat(e))
      sendMessage(`[[${String(xs).replaceAll(',', ', ')}], [${String(ys).replaceAll(',', ', ')}]]`, false)

      setTableInput([
        [...Array(tableSize).keys()].map(e => ""),
        [...Array(tableSize).keys()].map(e => "")
      ])
    } else if (type === 'UnorderedList') {
      const xs = '"' + tableInput[0].join('", "') + '"'
      console.log(xs)
      sendMessage(`[${xs}]`, false)

      setTableInput([
        [...Array(tableSize).keys()].map(e => ""),
        [...Array(tableSize).keys()].map(e => "")
      ])
    } else if (type === 'TwoWayTable') {
      //this prepares the table data to be submitted to backend
      const xs = twoWayTableInput[0].map(e => parseFloat(e))
      const ys = twoWayTableInput[1].map(e => parseFloat(e))
      const zs = twoWayTableInput[2].map(e => parseFloat(e))
      sendMessage(`[[${String(xs).replaceAll(',', ', ')}], [${String(ys).replaceAll(',', ', ')}], [${String(zs).replaceAll(',', ', ')}]]`, false)

      setTwoWayTableInput([
        [...Array(tableSize).keys()].map(e => ""),
        [...Array(tableSize).keys()].map(e => ""),
        [...Array(tableSize).keys()].map(e => "")
      ])
    } else if (type === 'Set') {
      sendMessage('[' + String(mathSetInput) + ']')
      modifyMathSetInput("", 0)
      modifyMathSetInput("", 1)
    } else if (type === 'ApproximatedSet') {
      sendMessage('[' + String(mathSetInput) + ']')
      modifyMathSetInput("", 0)
      modifyMathSetInput("", 1)
    } else if (type === 'ApproximatedEquation') {
      sendMessage('y = ' + String(mathSetInput[0]) + 'x + ' + String(mathSetInput[1]))
      // sendMessage('[' + String(mathSetInput) + ']')
      modifyMathSetInput("", 0)
      modifyMathSetInput("", 1)
    } else {
      console.log(newAnswer)
      sendMessage(newAnswer, isExpression)
    }
    setNewAnswer("")
  }

  return <div className="flexRow chatInputRow">
    {type !== 'Expression' && (
      <label style={{ fontSize: "1.2em" }}>Submit Answer: &nbsp;</label>
    )}
    {type === 'Expression' ? (
      <div className={styles.expressionBox}>
        <math-field
          className={styles.expressionInput}
          onInput={(evt) => setNewAnswer(evt.target.value)}
          onKeyDown={answerOnKeyDown}
          id="formula-input"
          placeholder="\textrm{Final Answer}"
        >
          {newAnswer}
        </math-field>
        <img
          src="/greensend.png"
          alt="Submit"
          className={styles.expressionSubmit}
          onClick={submitAnswer}
        />
      </div>
    )  : type === 'Boolean' ? <select className='newAnswer'
        value={newAnswer} onChange={(e) => setNewAnswer(e.target.value)} onKeyDown={answerOnKeyDown}>
        <option value="" disabled>Select True or False</option>
        <option value="True">True</option>
        <option value="False">False</option>
    </select> : type === 'Table' ? <table className='newAnswer'><tbody>
      <tr>
        <td>X values</td>
        {Array(tableSize).fill().map((_, i) => <td key={i} className='pointTableCell'>
          <input type="text" value={tableInput[0][i]} onChange={(e) => modifyTableInput(0, i, e.target.value)}/>
        </td>)}
      </tr>
      <tr>
        <td>Y values</td>
        {Array(tableSize).fill().map((_, i) => <td key={i} className='pointTableCell'>
          <input type="text" value={tableInput[1][i]} onChange={(e) => modifyTableInput(1, i, e.target.value)}/>
        </td>)}
      </tr>
    </tbody></table> : type === 'Set' ? <div>
      (<input type="text" value={mathSetInput[0]} onChange={(e) => modifyMathSetInput(e.target.value, 0)}/>, <input type="text" value={mathSetInput[1]} onChange={(e) => modifyMathSetInput(e.target.value, 1)}/>)&nbsp;&nbsp;
    </div> : type === 'Number' ? <input type="number" className='newAnswer' style={{width: "40%"}} value={newAnswer} 
      onChange={(e) => setNewAnswer(e.target.value)} onKeyDown={answerOnKeyDown}
    /> : type === 'Chat' ? <div style={{display: 'contents'}}><textarea type="text" className='newMessage' style={{width: "200%"}} value={newAnswer} 
    onChange={(e) => setNewAnswer(e.target.value)} onKeyDown={answerOnKeyDown}></textarea>
    <style>{`#chatBox { display: none; }`}</style></div> : type === 'UnorderedList' ? <table className='newAnswer'><tbody>
      <tr>
        <td>Lengths</td>
        {Array(tableSize).fill().map((_, i) => <td key={i} className='pointTableCell' width='100px'>
          <math-field style={{width: '90%'}} onInput={(e) => modifyTableInput(0, i, e.target.value)}>{tableInput[0][i]}</math-field>
        </td>)}
      </tr>
    </tbody></table> : type === "NumSolutions" ? <select value={newAnswer} onChange={e=> setNewAnswer(e.target.value)}><option value="Infinity">Infinity</option><option value="None">None</option><option value="One">One</option></select>
    : type === "AssociationType" ?
      <select className='associationType'
          value={newAnswer} onChange={(e) => setNewAnswer(e.target.value)} onKeyDown={answerOnKeyDown}>
          <option value="" disabled>Select association type</option>
          <option value="Positive Linear">Positive Linear</option>
          <option value="Negative Linear">Negative Linear</option>
          {/*<option value="Nonlinear">Nonlinear</option>*/}
          <option value="No Association">No Association</option>
      </select>
    : type === "ApproximatedSet" ?
      <div>
          (<input type="text" value={mathSetInput[0]} onChange={(e) => modifyMathSetInput(e.target.value, 0)}/>, <input type="text" value={mathSetInput[1]} onChange={(e) => modifyMathSetInput(e.target.value, 1)}/>)&nbsp;&nbsp;
      </div>
    : type === "TwoWayTable" ?
      <table className='newAnswer'><tbody>
        <tr>
          <td></td>
          <td colSpan='4' style={{textAlign: "center"}}>A</td>
        </tr>
        <tr>
          <td rowSpan='4'>B</td>
          <td></td>
          <td>Yes</td>
          <td>No</td>
          <td>Total</td>
        </tr>
        <tr>
          <td>Yes</td>
          {Array(tableSize).fill().map((_, i) => <td key={i} className='pointTableCell'>
            <input type="text" value={twoWayTableInput[0][i]} onChange={(e) => modifyTwoWayTableInput(0, i, e.target.value)}/>
          </td>)}
        </tr>
        <tr>
          <td>No</td>
          {Array(tableSize).fill().map((_, i) => <td key={i} className='pointTableCell'>
            <input type="text" value={twoWayTableInput[1][i]} onChange={(e) => modifyTwoWayTableInput(1, i, e.target.value)}/>
          </td>)}
        </tr>
        <tr>
          <td>Total</td>
          {Array(tableSize).fill().map((_, i) => <td key={i} className='pointTableCell'>
            <input type="text" value={twoWayTableInput[2][i]} onChange={(e) => modifyTwoWayTableInput(2, i, e.target.value)}/>
          </td>)}
        </tr>
      </tbody></table>
    : type === "ApproximatedEquation" ?
        <div>
            Enter the slope and y intercept of the linear equation: y = <input type="text" value={mathSetInput[0]} onChange={(e) => modifyMathSetInput(e.target.value, 0)}/>x + <input type="text" value={mathSetInput[1]} onChange={(e) => modifyMathSetInput(e.target.value, 1)}/>&nbsp;&nbsp;
        </div>
    : <input type="text" className='newAnswer' style={{width: "40%"}} value={newAnswer} 
      onChange={(e) => setNewAnswer(e.target.value)} onKeyDown={answerOnKeyDown}
    />}
    {type !== 'Expression' && (
      <button onClick={submitAnswer} disabled={props.disabled}>
        Submit
      </button>
    )}
  </div>
}